import Carousel from 'react-bootstrap/Carousel';
import './../styles/component/home-slider.css';
import { Settings } from '../hooks/agent';
import { useEffect, useState } from 'react';
import { API_URL } from '../constants/app.constant';

export default function Slider() {
  const [sliderSetting, setSliderSetting] = useState({
    key: "",
    value: []
  });

  const fetchSlider = () => {
    const savedSetting = sessionStorage.getItem('home_slider') || null;
    if (savedSetting) {
      setSliderSetting(JSON.parse(savedSetting));
      return;
    }

    Settings.get('home_slider').then(res => {
      setSliderSetting(res.data);
      sessionStorage.setItem('home_slider', JSON.stringify(res.data))
    }).catch(err => {
      console.error(err);
    })
  }

  useEffect(() => {
    fetchSlider();
  }, []);

  return (
    <Carousel className="home-carousel" fade controls={false}>
      {sliderSetting.value.length > 0 && sliderSetting.value.map((slider, i) => (
        <Carousel.Item className="slider-item" key={i}>
          <div className="slide-layer"></div>
          <img
            className="d-block w-100"
            src={`${API_URL}/${slider.image}`}
            alt={`Banner ${i + 1}`}
          />
          <Carousel.Caption>
            <h1 className={slider.position === 'left' ? 'primary-label' : 'secondary-label'}>{slider.caption}</h1>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
