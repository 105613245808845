import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import gallery1 from "../../assets/images/gallery-1.png";
import gallery2 from "../../assets/images/gallery-2.png";
import gallery3 from "../../assets/images/gallery-3.png";
import gallery4 from "../../assets/images/gallery-4.png";
import gallery5 from "../../assets/images/gallery-5.png";
import gallery6 from "../../assets/images/gallery-6.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Settings } from "../../hooks/agent";
import { API_URL } from "../../constants/app.constant";

export default function PhotoGalleryMobile({ photos = [], ...props }) {
  const [configImages, setConfigImages] = useState([]);
  const [tagline, setTagline] = useState("");
  const [isError, setIsError] = useState(false);

  const fetchConfigImages = () => {
    const savedSetting =
      sessionStorage.getItem("home_photogallery_images") || null;
    if (savedSetting) {
      const saved = JSON.parse(savedSetting);
      console.log("saved => ", saved);
      setConfigImages(saved);
      return;
    }

    Settings.get("home_photogallery_images")
      .then((res) => {
        const images = JSON.parse(res.data.value);
        setConfigImages(images);
        sessionStorage.setItem("home_photogallery_images", res.data.value);
      })
      .catch((err) => {
        console.error(err);
        setIsError(true);
      });
  };

  const fetchTagline = () => {
    const savedTagline =
      sessionStorage.getItem("home_photogallery_tagline") || null;
    if (savedTagline) {
      setTagline(savedTagline);
      return;
    }

    Settings.get("home_photogallery_tagline")
      .then((res) => {
        setTagline(res.data.value);
        sessionStorage.setItem("home_photogallery_tagline", res.data.value);
      })
      .catch((err) => {
        console.error(err);
        const defaultTagline =
          "Best choice for rent a fleet, #MataTransJakarta!";
        setTagline(defaultTagline);
      });
  };

  useEffect(() => {
    fetchConfigImages();
    fetchTagline();
  }, []);

  useEffect(() => {
    const defaultPhotos = [
      gallery1,
      gallery2,
      gallery3,
      gallery4,
      gallery5,
      gallery6,
    ];

    if (isError) setConfigImages(defaultPhotos);
  }, [isError]);

  return (
    <Row className="photo-gallery-wrap mobile" {...props}>
      <Col className="tagline px-3">
        <p className="tagline-text">{tagline}</p>
      </Col>
      <Swiper
        modules={[Pagination]}
        // centeredSlides={true}
        // centeredSlidesBounds={true}
        slidesPerView={"auto"}
        loop={true}
        spaceBetween={30}
        effect={"coverflow"}
        autoHeight={true}
        pagination={{ clickable: true }}
      >
        {configImages.map((photo, i) => (
          <SwiperSlide key={i}>
            <div className="gallery-pic-wrap col-md-4 mb-4">
              <img className="img-gallery-mobile" src={`${API_URL}/${photo}`} alt={i} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Row>
  );
}
