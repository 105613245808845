import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CarSlider from "./CarSlider";
import { Cars } from "../hooks/agent";

export default function Gallery({ ...props }) {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    Cars.getAll().then((res) => {
      console.log(res);
      const carItems = res.data.items.map((car) => {
        return {
          ...car,
          new: car.carName === "Innova ZENIX",
        };
      });
      
      setCars(carItems);
    });
  }, []);

  return (
    <div {...props}>
      <Row className="gallery-wrap text-center">
        <Col className="header mb-4">
          <h3 className="text-dark font-semibold">Gallery</h3>
        </Col>
        <Col className="slider-content">
          <CarSlider cars={cars} />
        </Col>
      </Row>
    </div>
  );
}
