export class TimeUtils {
    static dateFormat(stringDate) {
        const splitDate = (new Date(stringDate)).toString().split(" ");
        const [dayName, monthName, date] = splitDate;
        return `${dayName}, ${date} ${monthName}`;
    }

    static localDateFormat(stringDate) {
        const splitDate = (new Date(stringDate)).toString().split(" ");
        const [dayName, monthName, date] = splitDate;
        let localDayName = "";
        switch (dayName) {
            case "Mon":
                localDayName = "Senin";
                break;
            case "Tue":
                localDayName = "Selasa";
                break;
            case "Wed":
                localDayName = "Rabu";
                break;
            case "Thu":
                localDayName = "Kamis";
                break;
            case "Fri":
                localDayName = "Jumat";
                break;
            case "Sat":
                localDayName = "Sabtu";
                break;
            default:
                localDayName = "Minggu";
        }

        let localMonthName = "";
        switch (monthName) {
            case "Jan":
                localMonthName = "Januari";
                break;
            case "Feb":
                localMonthName = "Februari";
                break;
            case "Mar":
                localMonthName = "Maret";
                break;
            case "Apr":
                localMonthName = "April";
                break;
            case "May":
                localMonthName = "Mei";
                break;
            case "Jun":
                localMonthName = "Juni";
                break;
            case "Jul":
                localMonthName = "Juli";
                break;
            case "Aug":
                localMonthName = "Agustus";
                break;
            case "Sep":
                localMonthName = "September";
                break;
            case "Oct":
                localMonthName = "Oktober";
                break;
            case "Nov":
                localMonthName = "November";
                break;
           default:
                localMonthName = "Desember";
        }


        return `${localDayName}, ${date} ${localMonthName}`;
    }

    static getYear(stringDate) {
        const splitDate = stringDate.split("-");
        return splitDate[0];
    }

    static getYearV2(stringDate) {
        const splitDate = String(stringDate).split(" ");
        return splitDate[3];
    }
}