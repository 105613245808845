import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Settings } from "../hooks/agent";
import parser from 'html-react-parser'

export default function Typography({ text = "", ...props }) {
  const [description, setDescription] = useState("");

  const fetchDescription = () => {
    const savedSetting = sessionStorage.getItem("home_description") || null;
    if (savedSetting) {
      setDescription(savedSetting);
      return;
    }

    Settings.get("home_description")
      .then((res) => {
        setDescription(res.data.value);
        sessionStorage.setItem("home_description", res.data?.value);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchDescription();
  }, []);

  return (
    <Container {...props}>
      {parser(description || text)}
    </Container>
  );
}
