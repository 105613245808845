import { Row, Col } from 'react-bootstrap';
import IconButton from './IconButton';
import { HiArrowRight } from 'react-icons/hi';

export default function ImageCard({ 
  image, 
  content, 
  buttonText, 
  ctaUrl = '', 
  direction = 'normal', 
  variant = 'orange', 
  ...props }) {
  return (
      <Row className={`image-card ${variant}`} {...props} style={direction === 'normal' ? {flexDirection: 'row-reverse'} : {}}>
        <Col className="col-8">
          <div className={`text-wrap${direction === 'reverse' ? ' text-right' : ''}`}>
            {content}
          </div>
          <div className={`button-wrap${direction === 'reverse' ? ' text-right' : ''}`}>
          <a href={ctaUrl} rel="noreferrer" target='_blank'>
            <IconButton
              text={buttonText}
              icon={<HiArrowRight />}
              size={"lg"}
              className="btn-light"
            />
          </a>
          </div>
        </Col>
        <Col className="col-4 p-0">
          <div className="image-wrap">
            <img src={image} alt="" className={`${direction === 'normal' ? 'left-border-radius' : 'right-border-radius'}`} />
          </div>
        </Col>
      </Row>
  );
}