import React from "react";
import "./../styles/component/badge.css";
import Button from "react-bootstrap/Button";
import { Vortex } from "react-loader-spinner";

export default function IconButton({ text, icon, isLoading = false,  ...props }) {
    return (
        <Button {...props} disabled={isLoading}>
            {isLoading && <Vortex
                height="100%"
                width={20}
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['blue', 'orange','blue', 'orange','blue', 'orange']}
            />}
            {text}
            {icon}
        </Button>
    )
}