import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Promo from "./pages/Promo";
import NavigationBar from "./components/Navbar";
import { useStyleMediaQuery } from "./utils/MediaQueries";
import NavbarMobile from "./components/mobile/NavbarMobile";
import { useState } from "react";
import PageLoader from "./components/PageLoader";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { aboutPageTransition, homePageTransition, navbarTransitions } from "./utils/Transitions";

function App() {
  const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: '500' });
  const [isLoading, setIsLoading] = useState(true);
  const [overflow, setOverflow] = useState("hidden");
  
  document.body.style.overflow = overflow;
  setTimeout(() => {
    setIsLoading(false);
    setOverflow("auto");
  }, 2000);

  window.onscroll = () => {
    navbarTransitions();
    homePageTransition(isMobile);
    aboutPageTransition(isMobile);
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PageLoader loading={isLoading} />
        <Router>
          {isMobile ? <NavbarMobile /> : <NavigationBar />}
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/promo" element={<Promo />}/>
          </Routes>
        </Router>
      </LocalizationProvider>
    </>
  );
}

export default App;
