import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../styles/component/search-box.css";
import { RiArrowLeftRightLine } from "react-icons/ri";
import { HiArrowRight } from "react-icons/hi";
import { GoCalendar } from "react-icons/go";
import { AiFillCar } from "react-icons/ai";
import Badge from "./Badge";
import IconButton from "./IconButton";
import Select from "react-select";
import { TimeUtils } from "../utils/TimeUtils";
import CarList from "./CarList";
import CheckPrice from "./CheckPrice";
import { Cars, Cities, RentPrices, Settings } from "../hooks/agent";
import { CiLocationOn } from "react-icons/ci";
import { LocationIcon } from "../constants/icons.constant";
import { useStyleMediaQuery } from "../utils/MediaQueries";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Notif from "./Notif";
import { formatPrice } from "../utils/Format";

export default function SearchBox() {
  const [cars, setCars] = useState([]);
  const [cities, setCities] = useState([]);
  const [pickupCities, setPickupCities] = useState([]);
  const [destinationCities, setDestinationCities] = useState([]);
  const [routePrice, setRoutePrice] = useState(0);

  const [searchCarLoading, setSearchCarLoading] = useState(false);
  const [selectedFromCity, setSelectedFromCity] = useState("");
  const [selectedFromCityId, setSelectedFromCityId] = useState("");
  const [selectedFromProvince, setSelectedFromProvince] = useState("Jakarta");
  const [hideSelectFromCity, setHideSelectFromCity] = useState(true);
  const [selectedToCity, setSelectedToCity] = useState("");
  const [selectedToProvince, setSelectedToProvince] = useState("Jawa Tengah");
  const [selectedToCityId, setSelectedToCityId] = useState("");
  const [hideSelectToCity, setHideSelectToCity] = useState(true);
  const [hideCarlist, setHideCarlist] = useState(true);
  const [hideSearchCarButton, setHideSearchCarButton] = useState(false);
  const [selectedCar, setSelectedCar] = useState("");
  const [selectedCarId, setSelectedCarId] = useState("");
  const [selectedCarObj, setSelectedCarObj] = useState(cars[0]);
  const [departureDate, setDepartureDate] = useState(new Date(Date.now()).toDateString());
  const [returnDate, setReturnDate] = useState(new Date(Date.now()).toDateString());
  const [departureYear, setDepartureYear] = useState(new Date(Date.now()).getFullYear());
  const [returnYear, setReturnYear] = useState(new Date(Date.now()).getFullYear());
  const [carListClassName, setCarListClassName] = useState("d-none");
  const [checkPriceClassName, setCheckPriceClassName] = useState("d-none");
  const [openDepartureDate, setOpenDepartureDate] = useState(false);
  const [openReturnDate, setOpenReturnDate] = useState(false);
  const [searchboxOpen, setSearchboxOpen] = useState(false);
  const [selectCityDecorated, setSelectCityDecorated] = useState(false);
  const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: '500' }); 

  const [chatMessage, setChatMessage] = useState("");

  const fetchChatMessage = () => {
    Settings.get("chat_booking")
      .then((res) => {
        let bookMessage = res.data.value
        bookMessage = bookMessage.replace('{car}', selectedCarObj?.carName)
        bookMessage = bookMessage.replace('{destination}', selectedToCity + ", " + selectedToProvince)
        bookMessage = bookMessage.replace('{pickup}', selectedFromCity + ", " + selectedFromProvince)
        bookMessage = bookMessage.replace('{dateTo}', TimeUtils.localDateFormat(departureDate) + " " + departureYear)
        bookMessage = bookMessage.replace('{dateFrom}', TimeUtils.localDateFormat(returnDate) + " " + returnYear)
        bookMessage = bookMessage.replace('{price}', formatPrice(routePrice))

        setChatMessage(bookMessage);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    fetchChatMessage();
  }, [
    selectedCarObj,
    selectedToCity,
    selectedToProvince,
    selectedFromCity,
    selectedFromProvince,
    departureDate,
    departureYear,
    returnDate,
    returnYear,
    routePrice,
  ])
  
  useEffect(() => {
    Cars.getAll().then(res => {
      const carItems = res.data.items.map(car => {
        return {
          ...car,
          active: false
        }
      });

      setCars(carItems);
    });

    Cities.getAll().then(res => {
      const pickupCityItems = res.data.items.filter(x => x.pickupExist === true).map(city => {
        return {
          value: city.cityName,
          label: city.cityName.toUpperCase(),
          province: city.province.provinceName,
          id: city.id
        }
      });
      
      const destinationCityItems = res.data.items.filter(x => x.destinationExist === true).map(city => {
        return {
          value: city.cityName,
          label: city.cityName.toUpperCase(),
          province: city.province.provinceName,
          id: city.id
        }
      });

      setCities(res.data.items);
      setPickupCities(pickupCityItems)
      setDestinationCities(destinationCityItems)
      setSelectedFromCity(pickupCityItems.find(x => x.value === 'Jakarta Selatan')?.value)
      setSelectedToCity(destinationCityItems.find(x => x.value === 'Surakarta')?.value)
      setSelectedFromCityId(pickupCityItems.find(x => x.value === 'Jakarta Selatan')?.id)
      setSelectedToCityId(destinationCityItems.find(x => x.value === 'Surakarta')?.id)
    });
  }, [])

  const selectCityFrom = useRef(null);
  const selectCityTo = useRef(null);
  const selectUpperContainer = useRef(null);
  const allContainer = useRef(null);

  const handleSelectCityFromClick = () => {
    setHideSelectFromCity(false);
    setTimeout(() => {
      selectCityFrom.current.openAfterFocus = true;
      selectCityFrom.current.focus();
    }, 100)
  };
  
  const handleSelectCityToClick = () => {
    setHideSelectToCity(false);
    setTimeout(() => {
        selectCityTo.current.openAfterFocus = true;
        selectCityTo.current.focus();
    }, 100)
  };

  const handleDepartureDateClick = () => {
    setOpenDepartureDate(true);
    document.body.onclick = () => {
      console.log(openDepartureDate)
      if (openDepartureDate) setOpenDepartureDate(false)
    }
  };
  
  const handleReturnDateClick = () => {
    setOpenReturnDate(true);
    document.body.onclick = () => {
      console.log(openReturnDate)
      if (openReturnDate) setOpenReturnDate(false)
    }
  };

  const handleSelectDepartureDate = (e) => {
    const fullDate = e?.$d;
    if (!fullDate) return
    const year = TimeUtils.getYearV2(fullDate);
    setDepartureYear(year);
    setDepartureDate(fullDate);
    if (!openReturnDate) {
      setOpenDepartureDate(false);
    }
  };

  const handleSelectReturnDate = (e) => {
    returnDateValid()
    const fullDate = e?.$d;
    if (!fullDate) return
    if (new Date(fullDate) < new Date(departureDate)) {
      errorNotif("Tanggal kembali tidak boleh kurang dari tanggal keberangkatan");
      returnDateError();
      return;
    }

    const year = TimeUtils.getYearV2(fullDate);
    setReturnYear(year);
    setReturnDate(fullDate);
    if (openDepartureDate) {
      setOpenDepartureDate(false);
      setOpenReturnDate(false);
    } else {
      setOpenReturnDate(false);
      console.log("setOpenReturnDate => ", openReturnDate)
    }
  };

  const handleSelectCityFromSelected = (e) => {
    setHideSelectFromCity(true);
    const selectedId = cities.find(x => x.cityName === e.value).id;
    setSelectedFromCityId(selectedId);
    setSelectedFromCity(pickupCities.find(x => x.value === e.value).label);
    setSelectedFromProvince(pickupCities.find(x => x.value === e.value).province);
    
    setHideSelectToCity(false);
    handleSelectCityToClick();
    handleClickSelectCity();
  };

  const handleSelectCityFromBlur = () => {
    setHideSelectFromCity(true);
  };

  const handleSelectCityToSelected = (e) => {
    setHideSelectToCity(true);
    const selectedId = cities.find(x => x.cityName === e.value).id;
    setSelectedToCityId(selectedId);
    setSelectedToCity(destinationCities.find(x => x.value === e.value).label);
    setSelectedToProvince(destinationCities.find(x => x.value === e.value).province);
    if (isMobile) return;
    setOpenDepartureDate(true);
    setOpenReturnDate(true);
  };

  const handleSelectCityToBlur = () => {
    setHideSelectToCity(true);
  };

  const handleSearchClick = () => {
    setSearchCarLoading(true)
    setSearchboxOpen(true)
    getRouteCars()
    setTimeout(() => {
      setHideCarlist(false);
      setCarListClassName("fade")
      setHideSearchCarButton(true);
      selectUpperContainer.current?.classList?.remove("box-shadow-grey");
      allContainer.current?.classList?.add("box-shadow-grey");
      setSearchCarLoading(false)
    }, 500)
  }

  const handleCarClick = () => {
    setSearchCarLoading(true)
    getRoutePrice()
    setTimeout(() => {
      setCheckPriceClassName("fade")
      setSearchCarLoading(false)
    }, 400)
  }

  useEffect(() => {
    const newCars = cars;
    newCars.forEach(x => x.active = false);
    const findChange = newCars.find(x => x.carName === selectedCar);
    if (findChange) {
      newCars.find(x => x.carName === selectedCar).active = true;
      const selectedId = newCars.find(x => x.carName === selectedCar).id;
      setSelectedCarId(selectedId);
    } 

    setCars(newCars);

    setSelectedCarObj(findChange);
  }, [selectedCar])

  const handleClickSelectCity = () => {
    if (selectCityDecorated) return;
    const wraps = document.querySelectorAll('.select-city .select-ov .basic-single');
    Array.from(wraps).forEach(wrap => {
      if (!wrap.querySelector('.select-city-h')) {
        wrap.insertAdjacentHTML('beforeend', '<div class="select-city-h">Select City</div>')
      }
    });

    setTimeout(() => {
      const selectOptionWraps = document.querySelectorAll('.select__menu .select__option');
      Array.from(selectOptionWraps).forEach(s => {
        const city = s.innerText.toLowerCase();
        const province = cities.find(x => x.cityName.toLowerCase() === city)?.province.provinceName; 
        if (!s.querySelector('svg')) {
          s.insertAdjacentHTML('afterbegin', LocationIcon + ' ');
          s.insertAdjacentHTML('beforeend', `<br />  <small style="margin-left:25px">${province}</small>`);
        }
      });
      setSelectCityDecorated(true);
    }, 10)
  }

  const handleReverseCity = e => {
    e.preventDefault();
    setSelectedFromCity(selectedToCity);
    setSelectedToCity(selectedFromCity);
  }

  const [notifOpen, setNotifOpen] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const errorNotif = (error, duration = 3000) => {
    setNotifMessage(error);
    setNotifOpen(true);
    setTimeout(() => {
      setNotifOpen(false);
    }, duration)
  }

  const returnDateError = () => {
    const returnDateWrap = document.querySelector('.MuiPickersLayout-contentWrapper');
    returnDateWrap.style.border = '1px solid red'
    returnDateWrap.style.background = '#fe6a6a12'
  }

  const returnDateValid = () => {
    const returnDateWrap = document.querySelector('.MuiPickersLayout-contentWrapper');
    returnDateWrap.style.border = 'none'
    returnDateWrap.style.background = 'inherit'
  }

  const getRouteCars = () => {
    const payload = {
      pickup_city_id: selectedFromCityId,
      destination_city_id: selectedToCityId,
    }

    RentPrices.getRouteCars(payload).then(res => {
      console.log('res => ', res)
      // const carItems = res.data;
      // setCars(carItems)
    })
  }

  const getRoutePrice = () => {
    const payload = {
      car_id: selectedCarId,
      pickup_city_id: selectedFromCityId,
      destination_city_id: selectedToCityId,
    }

    RentPrices.getRoutePrice(payload).then(res => {
      console.log('res => ', res)
      const price = res.data?.price ?? 0
      setRoutePrice(price)
    })
  }

  useEffect(() => {
    getRoutePrice()
  }, [selectedCarId, selectedFromCityId, selectedToCityId])

  return (
    <Col className="searchbox-wrap" ref={allContainer}>
      <Notif open={notifOpen} message={notifMessage} type={'error'} />
      <Container className="searchbox-container d-flex ov-badge-wrap">
        <Badge
          style={{ padding: "6px 15px", width: "170px" }}
          text={"Rent Car"}
          icon={<AiFillCar />}
        />
      </Container>
      {isMobile && <CiLocationOn size={20} />}
      <div className={`searchbox-container searchbox-city` + (searchboxOpen ? ' open' : '')} ref={selectUpperContainer} style={{width: "fit-content"}}>
        <Row className="select-city">
          <Col>
            <div className="select-box">
              <small>From</small>
              <div className="from-city-name d-flex">
                <h3 onClick={handleSelectCityFromClick}>{selectedFromCity?.toUpperCase()}</h3>
                <div className="circle" onClick={handleReverseCity}>
                  <div className="top-stamp"></div>
                  <RiArrowLeftRightLine size={"24px"} />
                  <div className="bottom-stamp"></div>
                </div>
              </div>
              <small className="d-block">{selectedFromProvince}</small>
            </div>
            <div className={`select-ov`} hidden={hideSelectFromCity}>
              <Select
                onFocus={handleClickSelectCity}
                onKeyDown={handleClickSelectCity}
                ref={selectCityFrom}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={selectedFromCity}
                name="from-city"
                placeholder=""
                options={pickupCities}
                onBlur={handleSelectCityFromBlur}
                onChange={handleSelectCityFromSelected}
              />
            </div>
          </Col>
          <Col>
            <div className="select-box" onClick={handleSelectCityToClick}>
              <small>To</small>
              <div className="to-city-name d-flex">
                <h3>{selectedToCity?.toUpperCase()}</h3>
              </div>
              <small className="d-block">{selectedToProvince}</small>
            </div>
            <div className="select-ov" hidden={hideSelectToCity}>
              <Select
                onFocus={handleClickSelectCity}
                onKeyDown={handleClickSelectCity}
                ref={selectCityTo}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={selectedToCity}
                name="to-city"
                placeholder=""
                options={destinationCities}
                onBlur={handleSelectCityToBlur}
                onChange={handleSelectCityToSelected}
              />
            </div>
          </Col>
        </Row>
        <Row className="select-date">
          <Col className="select-box" style={{ height: "80%" }}>
            <div className="d-flex w-100 select-date-container">
              <div className="departure-container">
                <b>Departure</b>
                <div className="p-0 d-flex departure-month" onClick={handleDepartureDateClick}>
                  <h3>{TimeUtils.dateFormat(departureDate)}</h3>
                  <GoCalendar size={"25px"} />
                </div>
                <small className="departure-year">{departureYear}</small>
                {/* {isMobile ? 
                  <div style={{ visibility: "hidden" }}>
                    <Form.Control
                        ref={selectDepartureDate}
                        type="date"
                        name="departure-date"
                        min={new Date().toISOString().split('T')[0]}
                        onChange={handleSelectDepartureDate}
                    />
                  </div> : */}
                  <div style={{ width: 'auto', visibility: (openDepartureDate ? "show" : "hidden") }}>
                    <DatePicker 
                      open={openDepartureDate} 
                      closeOnSelect={true} 
                      style={{ height: '0' }} 
                      onChange={handleSelectDepartureDate} 
                      // minDate={new Date(Date.now())}
                    />
                  </div>
                {/* } */}
              </div>
              <hr className="select-date-separator" />
              <div className="return-container">
                <b>Return</b>
                <div className="p-0 d-flex return-month" onClick={handleReturnDateClick}>
                  <h3>{TimeUtils.dateFormat(returnDate)}</h3>
                  <GoCalendar size={"25px"} />
                </div>
                <small className="return-year">{returnYear}</small>
                {/* {isMobile ? 
                  <div style={{ visibility: "hidden" }}>
                    <Form.Control
                        ref={selectReturnDate}
                        type="date"
                        name="return-date"
                        min={new Date().toISOString().split('T')[0]}
                        onChange={handleSelectReturnDate}
                    />
                  </div> : */}
                  <div style={{ width: 'auto', visibility: (openReturnDate ? "show" : "hidden") }}>
                    <DatePicker 
                      open={openReturnDate} 
                      closeOnSelect={true} 
                      style={{ height: '0' }} 
                      onChange={handleSelectReturnDate} 
                      // minDate={new Date(Date.now())} 
                    />
                  </div>
                {/* } */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <CarList cars={cars} handleSelect={setSelectedCar} className={carListClassName} />
      <Container className={`searchbox-container search-car d-flex ov-badge-wrap justify-content-end` + (searchboxOpen ? ' open' : '')}>
        <IconButton
          style={{
            marginTop: "-25px",
            borderRadius: "20px",
            width: "200px",
            height: "54px",
          }}
          text={"Search Car"}
          isLoading={searchCarLoading}
          icon={<HiArrowRight />}
          size={"lg"}
          className="bg-dark-blue btn-search-car"
          onClick={handleSearchClick}
          hidden={hideSearchCarButton}
        />
      </Container>
      <Container className="d-flex justify-content-center">
        <IconButton
            text={"Cek Harga"}
            size={"lg"}
            isLoading={searchCarLoading}
            className={"bg-dark-blue check-price-btn"}
            onClick={handleCarClick}
            hidden={hideCarlist}
            disabled={selectedCar !== ""}
        />
      </Container>
      <CheckPrice 
        selectedCar={selectedCarObj} 
        className={checkPriceClassName} 
        selectedPickup={selectedFromCity + ", " + selectedFromProvince}
        selectedDestination={selectedToCity + ", " + selectedToProvince}
        dateFrom={TimeUtils.localDateFormat(departureDate) + " " + departureYear}
        dateTo={TimeUtils.localDateFormat(returnDate) + " " + returnYear}
        price={routePrice}
        chatMessage={chatMessage}
      />
    </Col>
  );
}
