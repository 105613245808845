import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import IconButton from "./IconButton";
import HorizontalTimeline from "./HorizontalTimeLine";
import { API_URL } from "../constants/app.constant";
import { formatPhone, formatPrice } from "../utils/Format";

export default function CheckPrice({ 
  selectedCar = null,
  selectedDestination = "", 
  selectedPickup = "", 
  dateFrom = "", 
  dateTo = "", 
  price = 0,
  chatMessage = "",
  ...props }) {
  if (!selectedCar) {
    return ( <div></div> )
  } 

  const config = JSON.parse(sessionStorage.getItem("company") || {})

  return (
    <Container className="text-center check-price-wrap" {...props}>
      <div className="check-price-separator" />
      <Col className="check-price-detail">
        <Row>
          <div className="col check-price-rent">
            <h5>Persewaan</h5>
            <p>{dateFrom}</p>
            <Badge className="city-badge bg-dark-blue">
              {selectedPickup}
            </Badge>
          </div>
          <div className="col separator">
            <HorizontalTimeline length={2} />
          </div>
          <div className="col check-price-back">
            <h5>Kembali</h5>
            <p>{dateTo}</p>
            <Badge className="bg-dark-blue city-badge">
              {selectedDestination}
            </Badge>
          </div>
        </Row>
        <Row className="mt-5">
          <div className="d-flex car-background">
            <img style={{ maxWidth: "100%" }} src={`${API_URL}/${selectedCar?.carImage[0].asset}`} alt={selectedCar?.carName} />
            <div className="car-detail px-5">
              <h2>{selectedCar?.carName}</h2>
              <small>{selectedCar?.description && parse(selectedCar?.description)}</small>
            </div>
          </div>
        </Row>
        <Row className="d-flex mt-3 price-wrap">
          <div className="price-title w-triple">
            <h3 style={{ fontSize: "18px" }}>Total Harga</h3>
          </div>
          <div className="price-total w-triple">
            <h3 style={{ fontSize: "18px" }}>{formatPrice(price)}</h3>
          </div>
          <div className="action-button w-triple">
            <a href={`https://wa.me/${formatPhone(config?.company_contact_whatsapp)}?text=${chatMessage}`} rel="noreferrer" target="_blank">
              <IconButton
                text={"BOOK NOW"}
                size={"lg"}
                className="bg-dark-blue"
              />
            </a>
          </div>
        </Row>
      </Col>
    </Container>
  );
}
