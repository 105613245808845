import React, { useState } from "react";
import { Vortex } from "react-loader-spinner";

export default function PageLoader({ loading }) {
    const [opacity, setOpacity] = useState({});

    if (!loading) setTimeout(() => setOpacity({ display: 'none' }), 1000);
    return (
        <div style={opacity} className={(loading ? "page-loader" : "page-loader fade-out")}>
            <Vortex
                // visible={!loading}
                height="100%"
                width="100%"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['blue', 'orange','blue', 'orange','blue', 'orange']}
            />
        </div>
    )
}