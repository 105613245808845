import React from "react";
import { Col, Row } from "react-bootstrap";

export default function AdvantagesMobile() {
    return (
        <Row className="advantages-icons-mobile d-flex">
            { Array.from({ length: 18 })
                   .map((c, i) => (
                        <Col className="col-3">
                            <img src={require(`../../assets/images/advantage-icon-${i+1}.png`)} alt={`advantage-icon-${i+1}`} width="100%" />
                        </Col>
                    ))
            }
        </Row>
    );
}