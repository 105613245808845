import React, { useEffect, useState } from 'react';
import Gallery from '../components/Gallery';
import Typography from '../components/Typography';
import TextSlider from '../components/TextSlider';
import bgCity from '../assets/images/bg-city.png';
import PhotoGallery from '../components/PhotoGallery';
import ImageCard from '../components/ImageCard';
import custImage from '../assets/images/customer-service.png';
import carImage from '../assets/images/garage-car.png';
import { Row } from 'react-bootstrap';
import Maps from '../components/Maps';
import Footer from '../components/Footer';
import Slider from '../components/Slider';
import SearchBox from '../components/SearchBox';
import { useStyleMediaQuery } from '../utils/MediaQueries';
import PhotoGalleryMobile from '../components/mobile/PhotoGalleryMobile';
import { Settings } from '../hooks/agent';
import { API_URL } from '../constants/app.constant';

export default function Home() {
  const [companyConfig, setCompanyConfig] = useState({});

  const fetchCompanyConfig = () => {
    const savedSetting = sessionStorage.getItem("company") || null;
    if (savedSetting) {
      const saved = JSON.parse(savedSetting);
      setCompanyConfig(saved);
      return;
    }

    Settings.get("company")
      .then((res) => {
        setCompanyConfig(res.data.value);
        sessionStorage.setItem("company", JSON.stringify(res.data.value));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchCompanyConfig();
  }, []);

    const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: '500' });

    return (
      <div className={"home-page" + (isMobile ? '' : ' desktop')} data-root>
        <Slider />
        <SearchBox />
        <Gallery className={(!isMobile ? `car-slider animated ` : '')} />
        <Typography className={`typography animated`} />
        <TextSlider className={`text-slider-container animated`} />
        <div
          className="bg-city"
          style={{ height: "400px", background: `url(${bgCity})` }}
        ></div>
        {isMobile ? (
          <PhotoGalleryMobile photos={[]} />
        ) : (
          <PhotoGallery />
        )}
        <Row className={`contact-us-card mt-5 image-card-wrap animated`}>
          <ImageCard
            image={custImage}
            direction="reverse"
            content={"Have a Question About Mata Trans?"}
            buttonText={"Contact Us"}
            ctaUrl={`https://wa.me/${companyConfig?.company_contact_whatsapp?.replace('+', '').replace(/\s/g, '')}`}
          />
        </Row>
        <Row className={`compro-card mt-5 image-card-wrap animated`}>
          <ImageCard
            image={carImage}
            content={"Download Company Profile Here"}
            variant="blue"
            buttonText={"Company Profile"}
            ctaUrl={API_URL + `/${companyConfig?.company_profile_pdf}`}
          />
        </Row>
        {!isMobile && <Maps />}
        <Footer />
      </div>
    );
}