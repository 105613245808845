import React from "react";
import "./../styles/component/badge.css";

export default function Badge({ text, icon, ...props }) {
    return (
        <div className="overlay-badge" {...props}>
           {icon}
           <p className="w-auto">{text}</p>
        </div>
    )
}