import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import parse from "html-react-parser";
import { API_URL } from "../constants/app.constant";

export default function CarList({ cars = [], handleSelect = () => {}, ...props }) {
    return (
        <Col className="carlist-wrap text-center" {...props}>
            <Col className="header mb-4">
                <Badge className="mt-2 car-badge">Pilih Armada</Badge>
            </Col>
            {cars && cars.map((car, i) => (
                <Col key={i} onClick={() => handleSelect(car?.carName)} className={`car-wrap mb-3 ${car?.active ? 'active' : ''}`}>
                    <Row>
                        <div className="d-flex car-background">
                            <img src={`${API_URL}/${car?.carImage[0]?.asset}`} alt={car?.carName} />
                            <div className="car-detail">
                                <h2>{car?.carName}</h2>
                                <small>{parse(car?.description)}</small>
                            </div>
                        </div>
                    </Row>
                </Col>
            ))}
        </Col>
    );
}