import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../assets/images/logo.png";
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import footerLogo from '../../assets/images/logo-footer.png';
import bgCity from '../../assets/images/bg-city-solid.png';
import { Row } from 'react-bootstrap';
import { navbarMobileTransitions } from '../../utils/Transitions';
import { useStyleMediaQuery } from '../../utils/MediaQueries';

export default function NavbarMobile() {
  const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: '500' });

    const navItems = [
        { name: "HOME", path: "/" },
        { name: "ABOUT US", path: "/about-us" },
        { name: "PROMO", path: "/promo" },
    ];

    const url = useLocation();
    const [isShow, setIsShow] = useState(false);
    const navbar = useRef();

    if (isMobile) navbarMobileTransitions(isShow);

    useEffect(() => {
        if (isShow) {
            navbar.current.classList.remove('bg-transparent');
            navbar.current.classList.add('bg-transparent-dark');
            return;
        } 
        
        navbar.current.classList.add('bg-transparent');
        navbar.current.classList.remove('bg-transparent-dark');
    }, [isShow]);

    const handleToggleClick = () => setIsShow(!isShow);
    const navBrandClass = isShow ? 'd-none' : '';

  return (
    <Navbar ref={navbar} bg="transparent" expand="lg" sticky="top" className={`nav-mobile` + (isShow ? ' nav-open' : '')}>
      <Container>
        <Navbar.Brand href="" className={navBrandClass}>
          <a href="/">
            <img src={logo} alt="logo" width="50%" />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={handleToggleClick}
          aria-controls="basic-navbar-nav"
          // className={navBrandClass}
        />
        {/* <RxCross1 
          onClick={handleToggleClick}
          style={{ color: 'white', fontSize: 'large' }} 
          className={navToggleClass} 
        /> */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {navItems &&
              navItems.map((nav) => (
                <Nav.Link href={nav.path} key={nav.name} className={url.pathname === nav.path ? 'nav-item-underlined' : ''}>
                  <Nav.Item>{nav.name}</Nav.Item>
                </Nav.Link>
              ))}
          </Nav>
          <Row className="nav-brand-wrap">
            <div className="nav-brand-logo">
              <img src={footerLogo} alt="" />
            </div>
            <div className="nav-city-logo">
              <img src={bgCity} alt="" />
            </div>
          </Row>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}