export const navbarTransitions = () => {
      const navbar = document.querySelector(".navbar");
      const navMobile = document.querySelector(".nav-mobile");
      if (!navbar || navMobile) return;
      if (window.scrollY > 100) {
        navbar.classList.add("nav-scrolled");
        navbar.classList.remove("bg-transparent");
      } else {
        navbar.classList.remove("nav-scrolled");
        navbar.classList.add("bg-transparent");
      }
}

export const navbarMobileTransitions = (isShow) => {
    document.onscroll = () => {
        const navbar = document.querySelector(".nav-mobile");
        if (!navbar) return;
        if (isShow) return false;
        if (window.scrollY <= 10) return false;
        if (window.scrollY > 10) {
          navbar.classList.add("nav-height-auto");
          navbar.classList.add("nav-scrolled");
          navbar.classList.remove("bg-transparent");
          navbar.classList.remove("bg-transparent-dark");
        } else {
          navbar.classList.remove("nav-height-auto");
          navbar.classList.remove("nav-scrolled");
          navbar.classList.add("bg-transparent");
          navbar.classList.add("bg-transparent-dark");
        }
    }
};

export const homePageTransition = (isMobile) => {
    if (isMobile) return // temp disable 
    const isHome = document.querySelector(".home-page");
    if (!isHome) return;
    const scrollYFadeCar = isMobile ? 0 : 700;
    const scrollYFadeTypography = isMobile ? 100 : 1100;
    const scrollYFadeTextSlider = isMobile ? 250 : 1100;
    const scrollYFadeGallery = isMobile ? 400 : 1700;
    const scrollYFadeContact = isMobile ? 800 : 2200;
    const scrollYFadeCompro = isMobile ? 900 : 2300;

    const carSlider = document.querySelector(".home-page .car-slider");
    const description = document.querySelector(".home-page .typography");
    const textSlider = document.querySelector(".home-page .text-slider-container");
    const galleryPics = document.querySelectorAll(".home-page .gallery-pic-wrap");
    const contact = document.querySelector(".home-page .contact-us-card");
    const compro = document.querySelector(".home-page .compro-card");

    // document.onscroll = () => {
      // car slider fade transition
      if (window.scrollY > scrollYFadeCar) carSlider.classList.add('text-slider-transition-fade'); 
      // typography desc fade transition
      if (window.scrollY > scrollYFadeTypography) description.classList.add('text-slider-transition-fade'); 
      // text slider fade transition
      if (window.scrollY > scrollYFadeTextSlider) textSlider.classList.add('text-slider-transition-fade'); 
      if (window.scrollY > scrollYFadeGallery) {
        Array.from(galleryPics).forEach(galleryPic => {
            galleryPic.classList.add('text-slider-transition-fade'); 
        })
      }
      // contact us fade transition
      if (window.scrollY > scrollYFadeContact) contact.classList.add('text-slider-transition-fade'); 
      // download compro fade transition
      if (window.scrollY > scrollYFadeCompro) compro.classList.add('text-slider-transition-fade'); 
    // }
}

export const aboutPageTransition = (isMobile) => {
    if (isMobile) return // temp disable 
    const isAboutPage = document.querySelector(".about-page");
    if (!isAboutPage) return;
    const scrollYVisionMission = isMobile ? 100 : 600;
    const scrollYHeadquarter = isMobile ? 250 : 1200;
    const scrollYAdvantage = isMobile ? 800 : 1650;
    const scrollYService = isMobile ? 800 : 2600;
    const scrollYComValue = isMobile ? 900 : 3400;
    const scrollYComValueVision = isMobile ? 900 : 3750;

    const vision = document.querySelector(".about-page .vision-mission-wrap");
    const headquarters = document.querySelector(".about-page .headquarter-wrap");
    const advantages = document.querySelector(".about-page .advantages-wrap");
    const services = document.querySelector(".about-page .service-wrap");
    const comValues = document.querySelector(".about-page .company-value-wrap");
    const comValueVisions = document.querySelector(".about-page .company-value-vision");

    if (window.scrollY > scrollYVisionMission) vision.classList.add('text-slider-transition-fade'); 
    if (window.scrollY > scrollYHeadquarter) headquarters.classList.add('text-slider-transition-fade'); 
    if (window.scrollY > scrollYAdvantage) advantages.classList.add('text-slider-transition-fade'); 
    if (window.scrollY > scrollYService) services.classList.add('text-slider-transition-fade'); 
    if (window.scrollY > scrollYComValue) comValues.classList.add('text-slider-transition-fade'); 
    if (window.scrollY > scrollYComValueVision) comValueVisions.classList.add('text-slider-transition-fade'); 
}
