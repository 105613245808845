import React, { useEffect, useState } from 'react';
import ImageHeader from '../components/ImageHeader';
import { Button, Col, Container, Row } from 'react-bootstrap';
import parser from 'html-react-parser'
import bgThematic from '../assets/images/bg-thematic.png'
import bgCity from '../assets/images/bg-city.png'
import bgCitySolid from '../assets/images/bg-city-solid.png'
import advantageIcons from '../assets/images/advantage-icons.png'
import { TiLocation } from 'react-icons/ti';
import IconButton from '../components/IconButton';
import { HiArrowRight } from "react-icons/hi";
import cloudBg from '../assets/images/cloud.png';
import visionIcon from '../assets/images/vision-icon.png';
import missionIcon from '../assets/images/mission-icon.png';
import Footer from '../components/Footer';
import { useStyleMediaQuery } from '../utils/MediaQueries';
import ServiceMobile from '../components/mobile/ServiceMobile';
import AdvantagesMobile from '../components/mobile/AdvantagesMobile';
import { Settings } from '../hooks/agent';
import SkeletonLoading from '../components/Skeleton';
import { API_URL } from '../constants/app.constant';

export default function About() {
    const [companyConfig, setCompanyConfig] = useState({});
    const [imageHeader, setImageHeader] = useState(null);
    const [aboutCar, setAboutCar] = useState(null);

    const fetchCompanyConfig = () => {
      const savedSetting = sessionStorage.getItem("company") || null;
      if (savedSetting) {
        const saved = JSON.parse(savedSetting);
        setCompanyConfig(saved);
        return;
      }
  
      Settings.get("company")
        .then((res) => {
          setCompanyConfig(res.data.value);
          sessionStorage.setItem("company", JSON.stringify(res.data.value));
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const fetchHeaderImage = () => {
        const savedSetting = sessionStorage.getItem("about_header_image") || null;
        if (savedSetting) {
          const saved = JSON.parse(savedSetting);
          setImageHeader(saved);
          return;
        }

      Settings.get("about_header_image")
        .then((res) => {
          setImageHeader(res.data.value);
          sessionStorage.setItem("about_header_image", JSON.stringify(res.data.value));
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const fetchAboutImage = () => {
        const savedSetting = sessionStorage.getItem("about_description_image") || null;
        if (savedSetting) {
          const saved = JSON.parse(savedSetting);
          setAboutCar(saved);
          return;
        }

      Settings.get("about_description_image")
        .then((res) => {
          setAboutCar(res.data.value);
          sessionStorage.setItem("about_description_image", JSON.stringify(res.data.value));
        })
        .catch((err) => {
          console.error(err);
        });
    };
  
    useEffect(() => {
      fetchCompanyConfig();
      fetchAboutImage();
      fetchHeaderImage();
    }, []);

    const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: '500' }); 

    return (
        <div className={"about-page" + (isMobile ? '' : ' desktop')} data-root>
            {isMobile ? <ImageHeader image={imageHeader} /> :
            <ImageHeader separator={true} image={imageHeader} /> }
            <Row className="about-desc-wrap mt-3" style={{ flexDirection: isMobile ? 'column-reverse' : 'unset' }}>
                <Container className="col-6 about-desc-container">
                    <Button className="bg-dark-blue aboutus-badge" size="lg">About Us</Button>
                    <p className="about-desc">
                        {parser(companyConfig?.company_description || "") || <SkeletonLoading count={12} />}
                    </p>
                </Container>
                <Container className="col-6 p-5">
                    {aboutCar ? <img src={API_URL + '/' + aboutCar} alt="" width="100%" /> : <SkeletonLoading count={12} /> }
                </Container>
            </Row>
            <Row className="vision-mission-wrap animated mt-3 bg-dark-blue text-light">
                <Container className="col-6 vision-wrap" style={isMobile ? {} : { background: `url(${bgThematic})` }}>
                    <h3 className="vision-header">Vision</h3>
                    <p className="vision-desc mt-3">
                    {parser(companyConfig?.company_vision || "") || <SkeletonLoading count={2} />}
                    </p>
                </Container>
                <Container className="col-6 mission-wrap" style={isMobile ? {} : { background: `url(${bgCity})` }}>
                    <h3 className="mission-header">Mission</h3>
                    <p className="mission-desc mt-3">
                    {parser(companyConfig.company_mission || "") || <SkeletonLoading count={2} />}
                    </p>
                </Container>
                {isMobile && <div className="bg-city bg-city-mobile" style={{ height: "450px", background: `url(${bgCitySolid})` }}></div> }
            </Row>
            <Row className="headquarter-wrap animated mt-3">
                <Container className="col-12 headquarter-header-wrap">
                    <h3 className="headquarter-header text-dark-blue">MATA TRANS HEADQUARTER</h3>
                </Container>
                {companyConfig?.company_headquarters && companyConfig.company_headquarters.map(head => (
                    <Container className="col-6 headquarter-office" key={head.name}>
                        <Row>
                            <div className="headquarter-icon col-1">
                                <TiLocation />
                            </div>
                            <div className="headquarter-item col-11">
                                <p className="heading text-dark-blue">{head.name}</p>
                                <p className="address">{head.address}</p>
                                <IconButton
                                    text={"Lokasi"}
                                    icon={<HiArrowRight />}
                                    size={"md"}
                                    href={head.location_url}
                                    target="_blank"
                                    className="bg-dark-blue location"
                                />
                            </div>
                        </Row>
                    </Container>
                ))}
            </Row>
            <Row className="advantages-wrap animated mt-3 bg-dark-blue text-light d-grid">
                <Container className="col-12 advantages-header p-5">
                    <h3 className="text-center">Mata Trans Jakarta Advantages</h3>
                </Container>
                {isMobile ? <AdvantagesMobile /> :
                    <Container className="col-12 advantages-icons">
                        <img src={advantageIcons} alt="advantages" width="100%" />
                    </Container> 
                }
            </Row>
            <Row className="service-wrap animated">
                <Container className="col-12 mb-5 mt-5">
                    <h2 className="service-header text-dark-blue font-semibold text-center">SERVICE EXCELLENCE</h2>
                </Container>
                {isMobile && (companyConfig?.company_services && <ServiceMobile services={companyConfig?.company_services} />)}
                {!isMobile && (companyConfig?.company_services && companyConfig?.company_services.map((service, i) => (
                    <Container className="col-3 service-item mt-3" key={i}>
                        <div className="service-number">
                            <p className="font-small">{i+1}</p>
                        </div>
                        <img src={`${API_URL}/${service.image}`} alt="" className="service-img" width="100%" />
                        <p className="service-desc">{service.description}</p>
                    </Container>
                )))}
            </Row>
            <Row className="company-value-wrap animated mt-3">
                <Container className="col-12 mb-4 mt-4">
                    <h2 className="company-value-header">COMPANY VALUE</h2>
                </Container>
                <Row className="company-value-content mb-5">
                    <img className="left-cloud" src={cloudBg} alt="" />
                    {
                    // isMobile ? <CompanyValueMobile companyValues={companyValues} /> :
                    companyConfig?.company_values && companyConfig.company_values.map((company, i) => (
                        <Container className="col-4 service-item mt-3" key={i}>
                            <img src={`${API_URL}/${company.image}`} alt="" className="company-value-img" width="100%" />
                            <p className="company-value-desc mt-2 font-small">{company.description}</p>
                        </Container>
                    ))
                    }
                    <img className="right-cloud" src={cloudBg} alt="" />
                </Row>
                <Row className="company-value-vision animated mb-5">
                    <Container className="col-6">
                        <div className="d-flex">
                            <img src={visionIcon} alt="vision" />
                            <Col>
                                <p className="header-text">VISION</p>
                                <p className="font-small">{parser(companyConfig.company_mission || "") || <SkeletonLoading count={2} />}</p>
                            </Col>
                        </div>
                    </Container>
                    <Container className="col-6">
                        <div className="d-flex">
                            <img src={missionIcon} alt="mission" />
                            <Col>
                                <p className="header-text">MISSION</p>
                                <p className="font-small">{parser(companyConfig.company_mission || "") || <SkeletonLoading count={2} />}</p>
                            </Col>
                        </div>
                    </Container>
                </Row>
            </Row>
            <Footer />
        </div>
    );
}