import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);
const API_ROOT = process.env.REACT_APP_API_URL;
const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
}

const requests = {
  del: (url, body) =>
    superagent.del(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};

export const Cities = {
  getAll: () => requests.get('/cities')
};

export const Provinces = {
  getAll: () => requests.get('/provinces')
};

export const Cars = {
  getAll: () => requests.get('/cars')
};

export const Settings = {
  getAll: () => requests.get('/settings'),
  get: (key) => requests.get('/settings/'+key)
};

export const Promotions = {
  getAll: (limit = 3) => requests.get(`/promotions?limit=${limit}&order=id&orderBy=asc`),
  get: (key) => requests.get('/promotions/'+key),
  create: (payload) => requests.post('/promotions/', payload),
  put: (id, payload) => requests.put('/promotions/'+id, payload),
  delete: (payload) => requests.del('/promotions/', payload),
};

export const RentPrices = {
  getRoutePrice: (payload) => requests.post('/rent-prices/route', payload),
  getRouteCars: (payload) => requests.post('/rent-prices/car', payload),
};
