import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Settings } from "../hooks/agent";

export default function Maps() {
    const [mapsLocationUrl, setMapsLocationUrl] = useState("");
    useEffect(() => {
        Settings.get('maps_location_url').then(res => {
            setMapsLocationUrl(res.data.value);
        })
    }, []);

    return (
        <Row className="mt-5">
            <iframe className="maps" loading="lazy" src={mapsLocationUrl} title="Mata Trans Jakarta" aria-label="Mata Trans Jakarta"></iframe>
        </Row>
    );
}