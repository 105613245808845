import { GET_CARS } from "../constants/actionTypes";

const carReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CARS:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default carReducer;
