import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import newBadge from "../assets/images/new-badge.png";
import { Navigation, Scrollbar, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Badge from "./Badge";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useStyleMediaQuery } from "../utils/MediaQueries";
import { API_URL } from "../constants/app.constant";

export default function CarSlider({ cars = [], ...props }) {
    const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: '500' }); 
    const slidesPerView = isMobile ? 1.8 : 3;
    const modules = isMobile ? [Scrollbar] : [Navigation, Scrollbar, EffectCoverflow];

    if (!cars || cars.length === 0) {
    //   return (<PageLoader />);
      return ( <div></div> )
    }

    return (
        <Container {...props}>
            <Swiper
                modules={modules}
                centeredSlides={true}
                centeredSlidesBounds={true}
                slidesPerView={slidesPerView}
                loop={true}
                navigation={!isMobile}
                effect={'slide'}
                speed={600}
                spaceBetween={-10}
                autoHeight={true}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
            >
                {cars && cars.map(car => (
                    <SwiperSlide key={car.carName}>
                        <Container className="car-slide">
                            <Col className={`col bg-car ` + (isMobile ? 'bg-transgrey-color' : 'bg-default-grey')}>
                                <img className={`new-badge ${!car.new ? 'hidden' : ''}`} src={newBadge} alt="new badge" />
                                <img className="car-image" src={`${API_URL}/${car.carImage[0]?.asset}`} alt={car.carName} />
                                <Badge text={car.carName.toUpperCase()} className="bg-default-orange gallery-badge-car" />
                            </Col>
                        </Container>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Container>
    );
}