import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import bgCity from '../assets/images/bg-city-solid.png';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import { Settings } from "../hooks/agent";
import SkeletonLoading from '../components/Skeleton';
import { API_URL } from "../constants/app.constant";
import { formatPhone } from "../utils/Format";

export default function Footer() {
    const [companyConfig, setCompanyConfig] = useState({});

    const fetchCompanyConfig = () => {
      const savedSetting = sessionStorage.getItem("company") || null;
      if (savedSetting) {
        const saved = JSON.parse(savedSetting);
        setCompanyConfig(saved);
        return;
      }
  
      Settings.get("company")
        .then((res) => {
          setCompanyConfig(res.data.value);
          sessionStorage.setItem("company", JSON.stringify(res.data.value));
        })
        .catch((err) => {
          console.error(err);
        });
    };
  
    useEffect(() => {
      fetchCompanyConfig();
    }, []);

    return (
        <Row className="footer">
            <Container className="col-md-6 footer-container">
                <Col className="footer-logo">
                    <img src={API_URL + '/' + companyConfig?.company_footer_logo} alt="" />
                </Col>
                <Col className="footer-logo text-white mt-5">
                    <p className="company-name font-semibold">{companyConfig?.company_name || <SkeletonLoading />}</p>
                    <p className="company-address">{companyConfig?.company_address || <SkeletonLoading count={3} />}</p>
                    <p className="contact">
                        <div className="contact-item">
                            <a className="contact-link" href={`https://wa.me/${formatPhone(companyConfig?.company_contact_whatsapp || "")}`} rel="noreferrer" target="_blank">
                                <FaWhatsapp /> {companyConfig?.company_contact_whatsapp || <SkeletonLoading />}
                            </a>
                        </div>
                        <div className="contact-item">
                            <a className="contact-link" href={`mailto:${companyConfig?.company_contact_email}`} rel="noreferrer" target="_blank">
                                <GoMail /> {companyConfig?.company_contact_email || <SkeletonLoading />}
                            </a>
                        </div>
                        <div className="contact-item">
                            <a className="contact-link" href={`https://www.instagram.com/${companyConfig?.company_contact_instagram}/`} rel="noreferrer" target="_blank">
                                <FaInstagram /> {companyConfig?.company_contact_instagram || <SkeletonLoading />}
                            </a>
                        </div>
                    </p>
                </Col>
            </Container>
            <Container className="col-md-6 city-container">
                <img src={bgCity} alt="" />
            </Container>
        </Row>
    );
}