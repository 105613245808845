import React, { useEffect, useState } from 'react';
import ImageHeader from '../components/ImageHeader';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import Footer from '../components/Footer';
import { useStyleMediaQuery } from '../utils/MediaQueries';
import { Promotions, Settings } from '../hooks/agent';
import { API_URL } from '../constants/app.constant';
import { formatPhone } from '../utils/Format';
import IconButton from '../components/IconButton';

export default function Promo() {
    const [promotions, setPromotions] = useState([]);
    const [imageHeader, setImageHeader] = useState(null);
    const [chatMessage, setChatMessage] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);
    const [fetchPromoLimit, setFetchPromoLimit] = useState(3);

    const fetchChatMessage = () => {
      Settings.get("chat_promotion")
        .then((res) => {
          let promoMessage = res.data.value
          setChatMessage(promoMessage);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  
    useEffect(() => {
      fetchChatMessage();
    }, [])

    const fetchHeaderImage = () => {
        const savedSetting = sessionStorage.getItem("promo_header_image") || null;
        if (savedSetting) {
          const saved = JSON.parse(savedSetting);
          setImageHeader(saved);
          return;
        }

      Settings.get("promo_header_image")
        .then((res) => {
          setImageHeader(res.data.value);
          sessionStorage.setItem("promo_header_image", JSON.stringify(res.data.value));
        })
        .catch((err) => {
          console.error(err);
        });
    };
    
    useEffect(() => {
        fetchHeaderImage();
    }, []);

    useEffect(() => {
        setBtnLoading(true)
        Promotions.getAll(fetchPromoLimit).then(res => {
            const resPromos = res.data.items.sort((a, b) => a.promotionName > b.promotionName ? 1 : -1);
            setPromotions(resPromos);
            setBtnLoading(false);
        });
    }, [fetchPromoLimit]);

    const config = JSON.parse(sessionStorage.getItem("company") || {})
    const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: '500' }); 

    const addOtherPromo = () => {
        setFetchPromoLimit(fetchPromoLimit + 3)
    }

    return (
        <div className="promo-page" data-root>
            {isMobile ? <ImageHeader image={imageHeader} /> : <ImageHeader image={imageHeader} /> }
            <Row className="promo-wrap">
                <Container className="col-12 text-center badge-wrap">
                    <Badge className="promo-badge">PROMO</Badge>
                </Container>
                <Col className="promo-item-wrap mt-5">
                    {promotions && promotions.map(promo => (
                        <Row className="promo-item mb-5" key={promo.promotionName}>
                            <Container className="col-4 img-wrap">
                                <img src={API_URL + '/' + promo.promotionImage} alt={promo.promotionName} width="100%" />
                            </Container>
                            <Container className="promo-desc col-8">
                                <h1 className="promo-name">{promo.promotionName}</h1>
                                <p className="font-semibold">{promo.promotionDescription}</p>
                                <a href={`https://wa.me/${formatPhone(config?.company_contact_whatsapp || "")}?text=${chatMessage.replace('{promo}', promo?.promotionName)}`} rel="noreferrer" target="_blank">
                                    <Button className="bg-dark-blue">Info Selengkapnya</Button>
                                </a>
                            </Container>
                        </Row>
                    ))}
                    <Row className="d-flex justify-content-center w-auto mt-5 mb-5 font-bold">
                        <IconButton
                            onClick={addOtherPromo}
                            text={"Promo Lainnya"}
                            isLoading={btnLoading}
                            size={"md"}
                            className="bg-white text-orange w-auto"
                        />
                    </Row>
                </Col>
            </Row>
            <Footer />
        </div>
    );
}