export const formatPrice = (rawNumber) => {
    return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0
    }).format(rawNumber);
}

export const formatPhone = (numberString) => {
    return numberString.replace('+', '').replace(/\s/g, '');
}