import React from "react";
import "./../styles/header.css";
import { Row } from "react-bootstrap";
import { API_URL } from "../constants/app.constant";

export default function ImageHeader({ image, separator = false, ...props }) {
    return (
        <Row className="image-header" {...props}>
            <div className="overlay"></div>
            <img src={API_URL + '/' + image} alt="about-img" />
            { separator && <div className="header-separator"></div> }
        </Row>
    )
}