import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';

export default function Notif({ open, message, type, ...props }) {
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };



  return (
    <div>
      <Snackbar
        className={type}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
        {...props}
      />
    </div>
  );
}