import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./../styles/component/navbar.css";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Settings } from "../hooks/agent";
import { API_URL } from "../constants/app.constant";

export default function NavigationBar() {
  const [companyLogo, setCompanyLogo] = useState({});

  const fetchCompanyLogo = () => {
    Settings.get("company_logo")
      .then((res) => {
        setCompanyLogo(res.data.value);
        sessionStorage.setItem("company_logo", res.data.value);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchCompanyLogo();
  }, []);

  const url = useLocation();
  const navItems = [
    { name: "HOME", path: "/" },
    { name: "ABOUT US", path: "/about-us" },
    { name: "PROMO", path: "/promo" },
  ];

  const navbar = useRef();

  return (
    <>
      <Navbar ref={navbar} bg="transparent" variant="black">
        <Container className="nav-container">
          <Navbar.Brand className="brand-logo">
            <a href="/">
              <img src={API_URL + "/" + companyLogo} alt="logo" />
            </a>
          </Navbar.Brand>
          <Nav className="me-auto nav-link-wrap">
            {navItems &&
              navItems.map((nav) => (
                <Nav.Link href={nav.path}>
                  <Nav.Item
                    className={nav.path === url.pathname ? "active-nav" : ""}
                  >
                    {nav.name}
                  </Nav.Item>
                </Nav.Link>
              ))}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
