import React from "react";

export default function HorizontalTimeline({ length, ...props }) {
    return (
        <div className="horizontal-timeline" {...props}>
            <div class="timeline-line"></div>
            <div class="timeline-items">
            {Array.from({ length: length }).map((_, i) => (
                <div class="timeline-content" key={i}>
                    <div class="timeline-marker"></div>
                </div>
            ))}
              {/* <div class="timeline-content">
                <div class="timeline-marker"></div>
              </div> */}
            </div>
        </div>
    )
}