import React from "react";
import Container from "react-bootstrap/Container";
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import parser from 'html-react-parser';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { API_URL } from "../../constants/app.constant";

export default function ServiceMobile({ services = [] }) {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      loop
      speed={1000}
      spaceBetween={100}
      pagination
      navigation
      className="services-slider-wrap"
    > {services && services.map((service, i) => (
      <SwiperSlide key={i}>
        <Container className="col-4 service-item mt-3" key={i}>
            <div className="service-number">
              <p className="font-small">{i+1}</p>
            </div>
            <img src={`${API_URL}/${service.image}`} alt="" className="service-img" width="100%" />
            <p className="service-desc mt-2 font-small">{service.description}</p>
        </Container>
      </SwiperSlide>
    ))}
    </Swiper>
  );
};