import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navigation, EffectFlip, EffectCards, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import slideBg from "../assets/images/bg-thematic.png";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useStyleMediaQuery } from "../utils/MediaQueries";
import { Settings } from "../hooks/agent";

export default function TextSlider({ text = [], ...props }) {
  const [textSlider, setTextSlider] = useState({
    key: "",
    value: []
  });

  const fetchTextSlider = () => {
    const savedSetting = sessionStorage.getItem("home_text_slider") || null;
    if (savedSetting) {
      setTextSlider(JSON.parse(savedSetting));
      return;
    }

    Settings.get("home_text_slider")
      .then((res) => {
        setTextSlider(res.data);
        sessionStorage.setItem("home_text_slider", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchTextSlider();
  }, []);

  const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: '500' }); 

  const modules = isMobile ? [] : [Navigation, EffectFlip, EffectCards, EffectCoverflow];
  const effect = isMobile ? 'slide' : 'cards';
  const cardEffect = isMobile ? {} : { rotate: false, slideShadows: false, perSlideOffset: 4 };

  return (
    <div {...props}>
      <Swiper
        modules={modules}
        loop
        speed={1000}
        navigation={!isMobile}
        shortSwipes={isMobile}
        slidesPerView={'auto'}
        spaceBetween={0}
        cardsEffect={cardEffect}
        effect={effect}
        className="text-slider"
      > {textSlider.value.length > 0 && textSlider.value.map((txt, i) => (
        <SwiperSlide key={txt.title}>
          <Container className={`text-slide-wrap ` + (i % 2 === 0 ? 'bg-dark-blue' : 'bg-default-orange')} style={{ backgroundImage: `url(${slideBg})` }}>
            <p className="text-slider title">{txt.title}</p>
            <p className="text-slider content">{txt.description}</p>
          </Container>
        </SwiperSlide>
      ))}
      {text.length > 0 && text.map((txt, i) => (
        <SwiperSlide key={txt.title}>
          <Container className={`text-slide-wrap ` + (i % 2 === 0 ? 'bg-dark-blue' : 'bg-default-orange')} style={{ backgroundImage: `url(${slideBg})` }}>
            <p className="text-slider title">{txt.title}</p>
            <p className="text-slider content">{txt.description}</p>
          </Container>
        </SwiperSlide>
      ))}
      </Swiper>
    </div>
  );
};