import { combineReducers, createStore } from 'redux';
import carReducer from './reducers/car.reducer';
import cityReducer from './reducers/city.reducer';
import provinceReducer from './reducers/province.reducer';

const reducer = combineReducers({
    car: carReducer,
    city: cityReducer,
    province: provinceReducer,
});

export const store = createStore(
    reducer, 
    {
        car: [],
        city: [],
        province: []
    },
    window.devToolsExtension && window.devToolsExtension()
);